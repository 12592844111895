// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';
import { useTranslation } from "react-i18next/hooks";

import AdItems2 from "../../UserAdElements/SingleAdElement/AdItems2";
import AdminUpdateAdStatus from "./AdminUpdateAdStatus";
import Backdrop from "../../Backdrop/Backdrop";
import Button from "../../Button/Button";
import Loader from "../../Loader/Loader";
import SmallModal from "../../Modal/SmallModal";
import { createDisplayId } from "../../../util/ad-visit";
import { useStore } from "../../../hook-store/store";

import { BASE_URL } from "../../../App";

import classes from "./AdminAdElementContents.module.css";

const AdminAdElementContents = (props) => {
  console.log("AdminAdElementContents-props", props);
  
  const { 
    ad, 
    deleteAdElementHandler, 
    selectAdElementHandler,
    // adminUpdateAdStatusHandler,
    isLoading
  } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  // console.log('store SingleAdElementContents.js', store);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [adStatusChoice, setAdStatusChoice] = useState('');
  // console.log('store in SingleAdElement.js', store);

  useEffect(() => {
    if (store.languageNameList.length === 0) {
      const languageNameList = [];
  
      for (const element in resources) {
        languageNameList.push({
          code: element,
          LANGUAGE: resources[element].translation.LANGUAGE
        });
      }
      dispatch('SET_LANGUAGE_NAME_LIST', languageNameList);
    }
  },[]);

  let displayLngObj;

  if (ad && store.languageNameList.length > 0) {
    displayLngObj = store.languageNameList.find(lng => {
      return lng.code === ad.targetLanguage;
    });
  }

  const showDeleteModalHandler = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  let singleAdElementContentsBody = (
    <div className={classes.singleAdElementContainer} key={ad.adElementId}>
      
      <div>admin-adelement-contents</div>

      <div className={classes.singleAdElementContents}>
        ID: <strong>{createDisplayId(ad.adElementId)}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        Title: <strong>{ad.title}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        Description:
        <br />
        <strong>{ad.description}</strong>
      </div>
      <div>{/* start: {new Date(ad.start).toLocaleString()} */}</div>
      <div className={classes.singleAdElementContents}>
        Link URL: <strong>{ad.linkUrl}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        Start: <strong>{new Date(ad.start).toLocaleString()}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        End: <strong>{new Date(ad.end).toLocaleString()}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        Target Location:
        <br /> <strong>{ad.targetLocation.radiusKm}km</strong> radius, (center:
        Latitude: {ad.targetLocation.latitude.toFixed(2)}, Longitude:{" "}
        {ad.targetLocation.longitude.toFixed(2)})
      </div>
      
      {displayLngObj && (
        <div className={classes.singleAdElementContents}>
          Language: <strong>{displayLngObj.LANGUAGE}</strong>
        </div>
      )}

      <div className={classes.singleAdElementContents}>
        Budget / day: <strong>$ {(ad.budget)}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        Last Update Date: <strong>{new Date(ad.updatedAt).toLocaleString()}</strong>
      </div>
      <div className={classes.singleAdElementContents}>
        Creation Date:{" "}
        <strong>{new Date(ad.createdAt).toLocaleString()}</strong>
      </div>
      {ad.targetDevice && (
        <div className={classes.singleAdElementContents}>
          Target device:{" "}
          <strong>{ad.targetDevice}</strong>
        </div>
      )}
      <div>
        {/* <img src={ad.adImageUrl} alt="ad-img" height="100" /> */}
        <AdItems2 ad={ad} />
      </div>
      
       
        {/* <div className={classes.singleAdElementButtons}>
          <span className={classes.singleAdElementButton}>
            
            {ad && ad.end > Date.now() && 
              <Button
                design="raised"
                type="submit"
                disabled={isLoading}
                loading={isLoading}
                onClick={() => {
                  selectAdElementHandler(ad.adElementId);
                }}
              >
                Edit
              </Button>
            }
            
          </span>
          <span className={classes.singleAdElementButton}>
            <Button
              design="raised"
              type="submit"
              disabled={isLoading}
              loading={isLoading}
              onClick={showDeleteModalHandler}
            >
              Delete
            </Button>
          </span>
        </div> */}

        <AdminUpdateAdStatus 
          ad={ad}
        />

        {/* <div>
          <button>
            <Link to='/admin/ad-elements'>
              goback-to-list
            </Link>
          </button>
        </div> */}

    </div>
  );

  const deleteModalBody = (
    <div>
      <div>Is it no problem to delete this ad and image completely?</div>
      <div className={classes.singleAdElementButtons}>
        <span className={classes.singleAdElementButton}>
          <Button
            design="flat"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            onClick={showDeleteModalHandler}
          >
            Cancel
          </Button>
        </span>
        <span className={classes.singleAdElementButton}>
          <Button
            design="raised"
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            onClick={() => {
              deleteAdElementHandler(
                BASE_URL,
                localStorage.getItem("token"),
                ad.adElementId
              );
            }}
          >
            Delete
          </Button>
        </span>
      </div>
      {isLoading && <Loader />}
    </div>
  );

  return (
    <Fragment>
      {showDeleteModal && (
        <div>
          <Backdrop onClick={showDeleteModalHandler} />
          <SmallModal style="confirmModal">{deleteModalBody}</SmallModal>
        </div>
      )}
      {singleAdElementContentsBody}
    </Fragment>
  );
};
export default AdminAdElementContents;
