// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

// import AdminSearchAd from "../../components/SearchAd/AdminSearchAd";
import AdminAdElementsItem from "../AdminAdElements/AdminAdElementsItem";

// import AdminAccountStatus from "../AdminAccountStatus/AdminAccountStatus";
// import AdminSearchUser from "../../pages/Admin/AdminSearchUser";
// import Loader from "../Loader/Loader";
import Paginator from "../Paginator/Paginator";
// import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem";

import { useStore } from "../../hook-store/store";
// import { getUserDataForStore } from "../../util/user";
// import { getUserAdElements } from "../../util/ad-visit";
// import {
//   isAdminUser,
//   adminGetStatusAdElements,
// } from "../../util/admin-ad-element";

import { BASE_URL } from "../../App";

// import classes from "./AdminAdElements.module.css";

const AdminDisplayAdelements = (props) => {
  // console.log('AdminTopPage.js-props', props);
  const { isAuth, startEditAdHandler, displayAdElements } = props;

  const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { adminPendingAdElements, usersForAdmin, adminSelectedUser } =
    store.adminStore;

  // console.log('store in AdminTopPage.js', store);

  const [isLoading, setIsLoading] = useState(false);
  const [suggestId, setSuggestId] = useState("");
  const [suggest, setSuggest] = useState();
  const [pageNum, setPageNum] = useState(1);

  const [showUpdateAccountStatus, setShowUpdateAccountStatus] = useState(false);
  const [userIdForUpdate, setUserIdForUpdate] = useState("");

  const start = perPagePost * (pageNum - 1);
  const end = perPagePost * pageNum;

  // let adminDisplayAdsBody:

  // const start = perPagePost * (pageNum - 1);

  const adminDisplayAdsBody = (
    <ul>
      {displayAdElements.slice(start, end).map((ad) => {
        return (
          <div key={ad.adElementId}>
            <AdminAdElementsItem
              ad={ad}
              startEditAdHandler={startEditAdHandler}
            />
          </div>
        );
      })}
    </ul>
  );

  return (
    <Fragment>
      {/* {isLoading && (
        <div className="feed__loader">
          <Loader />
        </div>
      )} */}
      <div>{adminDisplayAdsBody}</div>
      <Paginator
        onPrevious={() => {
          setPageNum(pageNum - 1);
        }}
        onNext={() => {
          setPageNum(pageNum + 1);
        }}
        lastPage={Math.ceil(displayAdElements.length / perPagePost)}
        currentPage={pageNum}
      >
        {/* {feedPost2} */}
      </Paginator>
    </Fragment>
  );
};

export default AdminDisplayAdelements;
