// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
// import Generic from './Generic.jsx';

import { useTranslation } from "react-i18next/hooks";

// import AdminSearchAd from "../../components/SearchAd/AdminSearchAd";
// import AdminAdElementsItem from "../../components/AdminAdElements/AdminAdElementsItem";

// import AdminAccountStatus from "../../components/AdminAccountStatus/AdminAccountStatus";
// import AdminSearchUser from './AdminSearchUser';
import AdminDisplayAdelements from "../AdminDisplayAdElements/AdminDisplayAdElements";
import Loader from "../Loader/Loader";
// import Paginator from "../../components/Paginator/Paginator";
// import UserAdElementsItem from "../../components/UserAdElements/UserAdElementsItem";

import { useStore } from "../../hook-store/store";
// import { getUserDataForStore } from "../../util/user";
// import { getUserAdElements } from "../../util/ad-visit";
import { 
  isAdminUser,
  adminGetStatusAdElements,
 } from "../../util/admin-ad-element";

import { BASE_URL } from "../../App";

// import classes from './AdminAdElements.module.css';

const AdminPendingAdelements = (props) => {
  console.log('AdminTopPage.js-props', props);
  const { isAuth, startEditAdHandler, history } = props;

  // const perPagePost = 20;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { 
    adminPendingAdElements,
    usersForAdmin,
    adminSelectedUser,
  } = store.adminStore;

  // console.log('store in AdminTopPage.js', store);

  const [isLoading, setIsLoading] = useState(false);
  // const [suggestId, setSuggestId] = useState('');
  // const [suggest, setSuggest] = useState();
  // const [pageNum, setPageNum] = useState(1);

  // const [showUpdateAccountStatus, setShowUpdateAccountStatus] = useState(false);
  // const [userIdForUpdate, setUserIdForUpdate] = useState('');

  // useEffect(() => {
  //   if (isAuth && isAdminUser(store.userData) && usersForAdmin.length === 0) {
  //     getUsersForAdmin();
  //   }
  // },[isAuth, store.userData]);
  useEffect(() => {
    if (adminPendingAdElements.length === 0) {
      getPendingAdsHandler();
    }
  },[]);


  const getPendingAdsHandler = async () => {
    // console.log('in getUsersForAdmin');
    try {
      setIsLoading(true);
      const resData = await adminGetStatusAdElements(
        BASE_URL,
        localStorage.getItem('token'),
        'pending'
      );

      if (resData.data && resData.data.length > 0) {
        dispatch('SET_ADMIN_PENDINGADELEMENTS', resData.data);
      }

      setIsLoading(false);
    } catch(err) { 
      console.log(err); 
      setIsLoading(false);

      throw err;
    }
  }

  // const selectUserHandler = (userObj) => {
  //   dispatch('SET_ADMIN_SELECTEDUSER', userObj);
    
  //   if (userObj) {
  //     history.push('/admin/ad-elements');
  //   }
  // };

  // const setSuggestIdHandler = (adElementId) => {
  //   // console.log(adElementId);
  //   setSuggestId(adElementId);
  // };

  // const setSuggestHandler = (suggest) => {
  //   // console.log(suggest);
  //   setSuggest(suggest);
  // }

  let adminPendingAdsBody;

  if (isAdminUser(store.userData)) {
    adminPendingAdsBody = (
      <div>
        <AdminDisplayAdelements 
          displayAdElements={adminPendingAdElements}
        />
      </div>
    );
  }
  return (
    <Fragment>
      {isLoading && (
        <div className="feed__loader"><Loader /></div>
      )}
      <div>admin-pending-ads</div>
      <div style={{border: "1px solid blue"}}>
        {adminPendingAdsBody}
      </div>

    </Fragment>
  );
};

export default AdminPendingAdelements;
