// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next/hooks";

import Button from "../../Button/Button";
// import loader from "../Loader/Loader";

import { useStore } from "../../../hook-store/store";

import { updateUserPaused } from "../../../util/ad-element";

import { BASE_URL } from "../../../App";

import classes from './UserAdElementsAdStatus.module.css';

const UserAdElementsAdStatus = (props) => {
  // console.log('UserAdElementsItem-props', props);
  const { ad } = props;

  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { validDefaultMethod } = store.paymentStore;

  const [isLoading, setIsLoading] = useState(false);


  const updateUserPausedHandler = async (url, token, adElementId, userPaused) => {
    try {
      setIsLoading(true);

      const result = await updateUserPaused(url, token, adElementId, userPaused);
      
      console.log(result);
      
      const adList = store.userAdElements;

      const adIndex = adList.findIndex(element => {
        return element.adElementId === ad.adElementId;
      });

      adList[adIndex].userPaused = userPaused;

      dispatch('SET_USER_ADELEMENTS', adList);

      setIsLoading(false);
    } catch(err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  let isPaused = false;
  let isRunningAd = false;
  let notApproved = false;
  let isFutureAd = false;
  let isPending = false;

  if (ad && ad.end > Date.now() && ad.start < Date.now()) {
    isRunningAd = true;
    // console.log(ad.userPaused);
    if (ad.userPaused) {
      isPaused = true;
    }
  }

  if (ad && ad.adStatus === 'not-approved') {
    notApproved = true;
    isRunningAd = false;
  }

  if (ad && ad.adStatus === 'pending') {
    isPending = true;
    isRunningAd = false;
  }


  if (ad.userPaused) {
    isPaused = true;
    isRunningAd = false;
  }

  if (ad.start > Date.now()) {
    isFutureAd = true;
  }

  let adStatusBody;

  if (notApproved) {
    adStatusBody = (
      <div className={classes.adElementItemRunning}>
        <strong>
          Inactive, Not Approved Ad
        </strong>
      </div>
    );
  }

  // if (isRunningAd && !isPaused) {
  if (!isPaused) {
    adStatusBody = (
      <div className={classes.adElementItemRunning}>
        <span>
          {t('adList.text06', 'Active')}
          {isFutureAd && (
            <span>
              {' '}(not-started)
            </span>
          )}
          {isRunningAd && (
            <span>
              {' '}(running-ad)
            </span>
          )}
        </span>
        <span className={classes.adElementItemRunningButton}>
          <Button design="raised" type="submit" size="smaller"
            disabled={isLoading || !validDefaultMethod}
            loading={isLoading}
            onClick={() => {
              updateUserPausedHandler(
                BASE_URL,
                localStorage.getItem('token'),
                ad.adElementId,
                'paused'
              );
            }}
          >
            {t('adList.text07', 'Pause')} {t('general.text46', 'ad')} 
          </Button>
        </span>
      </div>
    );
  }

  // if (isRunningAd && isPaused) {
  if (isPaused) {
    adStatusBody = (
      <div className={classes.adElementItemRunning}>
        <span>
          {t('adList.text08', 'Paused')}
          {isFutureAd && (
            <span>
              {' '}(not-started)
            </span>
          )}
        </span>
        <span className={classes.adElementItemRunningButton}>
          <Button design="raised" type="submit" size="smaller"
            disabled={isLoading || !validDefaultMethod}
            loading={isLoading}
            onClick={() => {
            updateUserPausedHandler(
              BASE_URL,
              localStorage.getItem('token'),
              ad.adElementId,
              ''
            );
          }}
          >
            {t('adList.text09', 'Restart')} {t('general.text46', 'ad')} 
          </Button>
        </span>
      </div>
    );
  }

  if (isPending) {
    adStatusBody = (
      <div className={classes.adElementItemRunning}>
        <span>
          {isFutureAd && (
            <span>
              {' '}(not-started)
            </span>
          )}
          <span>
            {' '}pending ad
          </span>
        </span>
        <span className={classes.adElementItemRunningButton}>
          <Button design="raised" type="submit" size="smaller"
            disabled={isLoading || !validDefaultMethod}
            loading={isLoading}
            onClick={() => {
              updateUserPausedHandler(
                BASE_URL,
                localStorage.getItem('token'),
                ad.adElementId,
                'paused'
              );
            }}
          >
            {t('adList.text07', 'Pause')} {t('general.text46', 'ad')} 
          </Button>
        </span>
      </div>
    );
  }

  // console.log(isPaused, isRunningAd);

  return (
    <Fragment>
      {ad && ad.end < Date.now() && (
        <div>{t('adList.text04', 'Already Finished')}</div>
      )}
      {ad && ad.end >= Date.now() && (
        <div>{adStatusBody}</div>
      )}
    </Fragment>
  );
};

export default UserAdElementsAdStatus;
