// const React = window.React = require('react');
import React from "react";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import Generic from './Generic.jsx';
import { useTranslation } from "react-i18next/hooks";
import Img from "react-cool-img";

import AdminAdElementContents from "./AdminAdElement/AdminAdElementContents";
import Backdrop from "../Backdrop/Backdrop";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";
import { useStore } from "../../hook-store/store";

import { createDisplayId } from "../../util/ad-visit";
import { isImageFile, isVideoFile } from "../../util/image";
// import { BASE_URL } from "../../App";

import classes from './AdminAdElementsItem.module.css';

const AdminAdElementsItem = (props) => {
  // console.log('UserAdElementsItem-props', props);
  const { startEditAdHandler, ad } = props;

  const [t] = useTranslation("translation");

  // const [store, dispatch] = useStore();
  // console.log('store in UserAdElement.js', store);

  const [showAdModal, setShowAdModal] = useState(false);

  // const deleteAdElement = (url, token, adElementId) => {
  //   fetch(url + `/ad-element/${adElementId}`, {
  //     method: "DELETE",
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((resData) => {
  //       console.log(resData);

  //       getUserAdElements(BASE_URL, localStorage.getItem("token"));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const adPath = `/admin/ad-element/${ad.adElementId}`;

  let imagesBody;
  let fileType;

  if (ad && ad.adImagePath) {
    fileType = ad.adImagePath.split('.')[ad.adImagePath.split('.').length - 1].toLowerCase();
  } 


  if (fileType && isImageFile(fileType)) {
    imagesBody = (
      <div>
        {/* <div style={{ color: "red" }}>{ad.adElementId}</div> */}
        <Img className={classes.adElementItemImage} 
          src={ad.adImageUrl} alt="ad-img" 
        />
        {ad.ad150x500ImageUrl && (
          <Img className={classes.adElementItemImage150x500} 
            src={ad.ad150x500ImageUrl} alt="ad-150x500" 
          />
        )}
        {ad.ad600x130ImageUrl && (
          <Img className={classes.adElementItemImage600x130} 
            src={ad.ad600x130ImageUrl} alt="ad-600x130" 
          />
        )}
      </div>
    );
  }

  if (fileType && isVideoFile(fileType)) {
    imagesBody = (
      <div>
        <video className={classes.adElementItemVideo}
          src={ad.adImageUrl} controls alt="ad video"
        />
        <div>video-ad</div>
      </div>
    );
  }

  let userAdElementsItemBody;

  userAdElementsItemBody = (
    <div className={classes.adElementItemContainer}
      key={ad.adElementId}
      onClick={() => { setShowAdModal(!showAdModal); }}
    >
      <div>admin-adelements-item</div>
      {/* <Link to={adPath}> */}
        {/* <div style={{ color: "red" }}>{ad.adElementId}</div> */}
        {imagesBody}
        <div className={classes.adElementItemTitle}>{ad.title}</div>
        <div>Start: {new Date(ad.start).toLocaleString()}</div>
        <div>End: {new Date(ad.end).toLocaleString()}</div>
        <div>Creation Date: {new Date(ad.createdAt).toLocaleString()}</div>
        <div>ID: {createDisplayId(ad.adElementId)}</div>
        <div>AdStatus: {ad.adStatus}</div>
      {/* </Link> */}

      {ad && ad.end < Date.now() && 
        <div>Already Finished</div>
      }
      

      {/* <div>
      <button
        onClick={() => { 
          deleteAdElement(BASE_URL, localStorage.getItem('token'), ad.adElementId);
        }}
      >
        delete-ad
      </button>
    </div> */}

    {/* {ad && ad.end > Date.now() && 
      <div className={classes.adElementItemButtons}>
        <Button design="flat" type="submit" 
          onClick={() => {
            startEditAdHandler(ad.adElementId);
          }}
        >
          Edit
        </Button>
      </div>
    } */}
    <div style={{}}>UserId: {ad.adElementId.split('-')[0]}</div>
    </div>
  );

  return <Fragment>
    {userAdElementsItemBody}

    {showAdModal && (
      <div>
        <Backdrop onClick={() => { setShowAdModal(false) }} />
        <Modal
          title=""
          acceptEnabled={false}
          onCancelModal={() => { setShowAdModal(false) }}
          onAcceptModal={() => {}}
          // isLoading={isLoading}
        >
          admin-adelements-item-modal-content
          <div>
            <button
              onClick={() => { setShowAdModal(false) }}
            >
              close
            </button>
          </div>

          <AdminAdElementContents 
            ad={ad}
            // deleteAdElementHandler={deleteAdElementHandler}
            // selectAdElementHandler={selectAdElementHandler}
            // // adminUpdateAdStatusHandler={adminUpdateAdStatusHandler} 
            
            // isLoading={isLoading}
          />
        </Modal>
      </div>
    )}
    </Fragment>;
};
export default AdminAdElementsItem;
